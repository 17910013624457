<template>
  <div class="container">
    <div class="columns is-centered">
      <h3 class="title is-3">
        <span v-if="editing">Edit collection</span>
        <span v-else>Create new collection</span>
        <!-- <button class="button is-danger is-small" @click="destroyBook">Delete book</button> -->
      </h3>
    </div>
    <form v-if="!loading" v-on:submit.prevent="submitCollection">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Name</label>
            <input class="input" type="text" placeholder="Enter the name this collection" v-model="collection.attributes.name" />
          </div>
          <div class="field">
            <label class="label">Number</label>
            <input class="input" type="text" placeholder="Enter an optional number for this collection" v-model="collection.attributes.number" />
          </div>
          <div class="field">            
            <input type="checkbox" v-model="collection.attributes.active" />
            <label class="label">Active?</label>
            <p class="hint is-size-7">Inactive collections will be hidden from the public view in the online catalogue. Eventually.</p>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <button type="submit" class="button is-primary">Save</button>
        </div>
      </div>
  </form>
    <div v-else>
      <img src="@/assets/ajax-loader.gif" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      editing: false,
      loading: true,
      collection: {
        attributes: {
          name: undefined,
          number: undefined,
          active: true
        }
       }
    }
  },
  methods: {
    submitCollection () {
      let method = 'post'
      let url = `/v1/collections`
      if (this.$route.params.id) {
        method = 'put'
        url += `/${this.$route.params.id}`
      }
      this.axios({method: method, url: url, data: this.collection.attributes })
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
              this.$router.push({ name: 'Collections'})
          } else {

          }

        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  mounted () {    
    if (this.$route.params.id) {
      this.editing = true
      let DATA_URL = `/v1/collections/${this.$route.params.id}`
      this.axios.get(DATA_URL)
      .then((response) => {
        this.collection = response.data.data
        this.loading = false
      })
      .catch(error => {
        // console.log(error)
      })
    } else {
      this.loading = false
    }
  }
}
</script>